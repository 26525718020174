<template>
  <div class="college_CJ">
    <div class="container">
      <div v-loading="loading" class="Main">
        <div class="mb">
          <span>创建企业学堂</span>
        </div>
        <el-steps :active="active" align-center finish-status="success" class="step">
          <el-step title="学堂基本信息"></el-step>
          <el-step title="主体信息"></el-step>
          <el-step title="完成"></el-step>
        </el-steps>
        <el-form label-width="480px" class="demo-ruleForm" :label-position="labelPosition">
          <el-form-item label="主体性质:" required>
            <template>
              <el-radio-group v-model="radio" @change="radiochange">
                <el-radio label="0">企业</el-radio>
                <el-radio label="1">个人</el-radio>
              </el-radio-group>
            </template>
          </el-form-item>
        </el-form>

        <el-form
          v-show="Isshow"
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="480px"
          :label-position="labelPosition"
        >
          <!-- 选中企业性质时出现的form表 -->
          <el-form-item label="企业/机构名称:" prop="Collname">
            <el-input
              v-model="ruleForm.Collname"
              placeholder="企业/机构主体名称"
              class="inputer"
            ></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码:" prop="iCardId">
            <el-input
              v-model="ruleForm.iCardId"
              placeholder="请输入统一社会信用代码"
              class="inputer"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业/机构所属行业:" prop="region">
            <el-select v-model="ruleForm.region" placeholder="请选择" class="inputer">
              <el-option label="农、林、牧、渔业2、采矿业" value="1"></el-option>
              <el-option label="采矿业" value="2"></el-option>
              <el-option label="制造业" value="3"></el-option>
              <el-option label="电力、燃气及水的生产和供应业" value="4"></el-option>
              <el-option label="建筑业" value="5"></el-option>
              <el-option label="交通运输、仓储和邮政业" value="6"></el-option>
              <el-option label="信息传输、计算机服务和软件业" value="7"></el-option>
              <el-option label="批发和零售业" value="8"></el-option>
              <el-option label="住宿和餐饮业" value="9"></el-option>
              <el-option label="金融业" value="10"></el-option>
              <el-option label="房地产业" value="11"></el-option>
              <el-option label="租赁和商务服务业13" value="12"></el-option>
              <el-option label="科学研究、技术服务和地质勘查业" value="13"></el-option>
              <el-option label="水利、环境和公共设施管理业" value="14"></el-option>
              <el-option label="居民服务和其他服务业" value="15"></el-option>
              <el-option label="教育" value="16"></el-option>
              <el-option label="卫生、社会保障和社会福利业" value="17"></el-option>
              <el-option label="文化、体育和娱乐业" value="18"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业营业执照:" required>
            <el-upload
              class="avatar-uploader"
              action=""
              :limit="1"
              :file-list="fileList"
              :http-request="uploadRequest"
              list-type="picture-card"
              accept=".jpg,.jpeg,.png,.JPG,.JPEG"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">
                图片大小最好在200 X200，支持扩展名：.png .jpg .jpeg...
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <el-form
          v-show="isshow"
          ref="ruleForm1"
          :model="ruleForm1"
          :rules="rules1"
          label-width="480px"
          :label-position="labelPosition"
        >
          <!-- 选中个人时出现的form表 -->
          <el-form-item label="真实姓名:" prop="realname">
            <el-input
              v-model="ruleForm1.realname"
              placeholder="主体名称"
              class="inputer"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号码:" prop="CardId">
            <el-input
              v-model="ruleForm1.CardId"
              placeholder="请输入您的证件号码"
              class="inputer"
            ></el-input>
          </el-form-item>
          <el-form-item label="常驻地区:" prop="Address">
            <el-cascader
              ref="myCascader"
              v-model="ruleForm1.Address"
              :options="total"
              placeholder="请选择省/市/区"
              class="inputer"
              @change="handleChange"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="身份证正反面:" required>
            <el-upload
              class="CardId-uploader"
              action=""
              :limit="1"
              :file-list="images_Card"
              :http-request="uploadimagesCard"
              list-type="picture-card"
              accept=".jpg,.jpeg,.png,.JPG,.JPEG"
              :on-remove="imageRemove"
            >
              <img src="@/assets/login/idcard-zm.png" class="el-icon-plus CardId-uploader-icon" />
              <div slot="tip" class="CardId-upload__tip">上传身份证人像面</div>
              <div slot="tip" class="CardId-upload__ts">支持扩展名：.png .jpg .jpeg...</div>
            </el-upload>
            <el-upload
              class="CardId-uploader"
              action=""
              :limit="1"
              :file-list="pic_Card"
              :http-request="uploadPicCard"
              list-type="picture-card"
              accept=".jpg,.jpeg,.png,.JPG,.JPEG"
              :on-remove="picRemove"
            >
              <img src="@/assets/login/idcard-fm.png" class="el-icon-plus CardId-uploader-icon" />
              <div slot="tip" class="CardId-upload__tip">上传身份证国徽面</div>
              <div slot="tip" class="CardId-upload__ts">支持扩展名：.png .jpg .jpeg...</div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div v-if="Isshow" class="commitbtn">
          <a @click="nextpage()">创建主体</a>
        </div>
        <div v-if="isshow" class="item-comm">
          <a @click="nextpage1()">创建主体</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { uploadimage, addtenant } from '@/api/create'
import total from '@/assets/address/city'
import to from 'await-to'
export default {
  data() {
    return {
      loading: false,
      value: [],
      total,
      active: 2,
      labelPosition: 'right',
      radio: '0',
      ruleForm: {
        Collname: '',
        iCardId: '',
        region: '',
      },
      ruleForm1: {
        realname: '',
        CardId: '',
        Address: [],
      },
      rules1: {
        realname: [
          { required: true, message: '请输入您的名称', trigger: 'blur' },
          { min: 0, max: 20, message: '长度不得大于20字符', trigger: 'blur' },
        ],
        CardId: [{ required: true, message: '请输入您的身份证号码', trigger: 'blur' }],
        Address: [{ required: true, message: '请选择地区', trigger: 'change' }],
      },
      rules: {
        Collname: [
          { required: true, message: '请输入企业/机构名称', trigger: 'blur' },
          { min: 4, max: 24, message: '长度在 4 到 24 个字符', trigger: 'blur' },
        ],
        iCardId: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
        region: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      organizationid: this.$route.query.organizationid,
      fileList: [], //企业上传的图片集合
      images_Card: [], //个人上传的身份证正面集合
      imgUrl: '',
      pic_Card: [], //个人上传的身份证反面集合
      picUrl: '',
      imageUrl: '',
      Isshow: true,
      isshow: false,
      provinceCode: '', //省编码
      provinceName: '', //省名称
      cityCode: '', //市编码
      cityName: '', //市名称
      districtCode: '', //区编码
      districtName: '', //区名称
    }
  },
  methods: {
    //企业营业照片
    async uploadRequest(file) {
      const params = new FormData() //创建FormData
      params.append('file', file.file)
      const [res, err] = await to(uploadimage(params))
      if (err) return this.$message.warning(err.msg)
      this.imageurl = res.data.objectUrl
      this.fileList.push({ url: res.data.objectUrl })
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    //个人身份证照片
    async uploadimagesCard(file) {
      const params = new FormData() //创建FormData
      params.append('file', file.file)
      const [res, err] = await to(uploadimage(params))
      if (err) return this.$message.warning(err.msg)
      this.imgUrl = res.data.objectUrl
      this.images_Card.push({ url: res.data.objectUrl })
    },
    imageRemove(file, images_Card) {
      this.images_Card = images_Card
    },
    //个人身份证反面照片
    async uploadPicCard(file) {
      const params = new FormData() //创建FormData
      params.append('file', file.file)
      const [res, err] = await to(uploadimage(params))
      if (err) return this.$message.warning(err.msg)
      this.picUrl = res.data.objectUrl
      this.pic_Card.push({ url: res.data.objectUrl })
    },
    picRemove(file, pic_Card) {
      this.pic_Card = pic_Card
    },
    //性质切换
    radiochange(val) {
      if (val == 0) {
        this.Isshow = true
        this.isshow = false
      } else {
        this.isshow = true
        this.Isshow = false
      }
    },
    handleChange(value) {
      this.provinceCode = value[0]
      this.provinceName = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[0]
      this.cityCode = value[1]
      this.cityName = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[1]
      this.districtCode = value[2]
      this.districtName = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[2]
    },
    //个人主体
    async nextpage1() {
      this.$refs['ruleForm1'].validate(async valid => {
        if (valid) {
          if (this.images_Card == '') {
            this.$message.error('请上传您的身份证正面~')
            return
          }
          if (this.pic_Card == '') {
            this.$message.error('请上传您的身份证反面~')
            return
          }
          this.loading = true
          const [res, err] = await to(
            addtenant({
              contactName: this.ruleForm1.realname,
              organizationId: parseInt(this.organizationid),
              credentials: this.ruleForm1.CardId,
              provinceCode: this.provinceCode,
              provinceName: this.provinceName,
              cityCode: this.cityCode,
              cityName: this.cityName,
              districtCode: this.districtCode,
              districtName: this.districtName,
              idCardFrontImage: this.imgUrl,
              idCardBackImage: this.picUrl,
              tenantCategory: 1,
              credentialsCategory: 1,
            }),
          )
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$router.push({
            path: '/createSuc',
            query: { name: res.data.organizationName, logo: res.data.logo },
          })
        }
      })
    },
    async nextpage() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          if (this.fileList == '') {
            this.$message.error('请上传企业执照~')
            return
          }
          this.loading = true
          const [res, err] = await to(
            addtenant({
              belongIndustry: parseInt(this.ruleForm.region),
              tenantName: this.ruleForm.Collname,
              organizationId: parseInt(this.organizationid),
              tenantCategory: 0,
              credentials: this.ruleForm.iCardId,
              businessLicense: this.imageurl,
              credentialsCategory: 0,
            }),
          )
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$router.push({
            path: '/createSuc',
            query: { name: res.data.organizationName, logo: res.data.logo },
          })
        }
      })
    },
  },
}
</script>
<style scoped>
.college_CJ {
  width: 100%;
  background: #f8f8f8;
}
.container {
  display: flex;
  justify-content: center;
}
.Main {
  width: 1200px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #ffffff;
}
.mb {
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}
.mb span {
  display: inline-block;
  font-size: 18px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: left;
  color: #4d4e5d;
  margin: 20px 27px;
}
.step {
  margin-top: 55px;
}
.Main >>> .el-step__head.is-success {
  color: #ff7b33;
  border-color: #ff7b33;
}
.Main >>> .el-step__title.is-success {
  color: #ff7b33;
}
.Main >>> .el-step__head.is-process {
  color: #a1a3a8;
  border-color: #a1a3a8;
}
.Main >>> .el-step__title.is-process {
  font-weight: 700;
  color: #a1a3a8;
}
.demo-ruleForm {
  margin-top: 80px;
}
.inputer {
  width: 350px;
}
.avatar-uploader >>> .el-upload {
  width: 120px;
  height: 120px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader >>> .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 138px;
  text-align: center;
  margin-top: -10px;
}
.el-upload__tip {
  line-height: 20px;
  margin: 10px;
}
.commitbtn {
  text-align: center;
  margin: 50px 0 0 0;
}
.commitbtn a {
  display: inline-block;
  width: 218px;
  height: 40px;
  background: #ff7b33;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  line-height: 40px;
  margin-bottom: 50px;
  cursor: pointer;
}
.image-jus {
  display: flex;
}
.CardId-uploader >>> .el-upload {
  width: 230px;
  height: 110px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: #ff7b33;
}
.CardId-uploader >>> .el-upload:hover {
  border-color: #409eff;
}
.CardId-uploader >>> .el-upload-list--picture-card .el-upload-list__item-actions {
  width: 230px;
  height: 110px;
}
.CardId-uploader >>> .el-upload-list--picture-card .el-upload-list__item {
  width: 230px;
  height: 110px;
}
.CardId-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 230px;
  height: 120px;
  line-height: 138px;
  text-align: center;
  margin-top: -5px;
}
.CardId-upload__tip {
  line-height: 10px;
  margin-top: 10px;
  margin-left: 50px;
  font-size: 14px;
  font-family: SourceHanSansCN-Normal;
  text-align: left;
  color: #333333;
}
.CardId-upload__ts {
  line-height: 0px;
  margin: 10px;
  color: #909399;
  padding-top: 10px;
  padding-bottom: 20px;
}
.item-comm {
  text-align: center;
  margin: 10px 0 0 0;
}
.item-comm a {
  display: inline-block;
  width: 218px;
  height: 40px;
  background: #ff7b33;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  line-height: 40px;
  margin-bottom: 50px;
  cursor: pointer;
}
</style>
